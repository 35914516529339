@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  background-color: var(--pill-bg-color, $color-white);
  color: var(--pill-color, $color-text-secondary);
  display: inline-flex;
  vertical-align: top;
  flex-flow: row nowrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  position: relative;
  border-radius: 1000px;
  overflow: hidden;
  min-height: 1em;
  padding: 0 6px;
  white-space: nowrap;
}

.pillChildren {
  display: flex;
  text-align: center;
  width: 100%;
}
.outline {
  background-color: transparent;
  color: var(--pill-bg-color, $color-text-primary);
  border: solid 1px;
  padding: 0.2em 0.8em;
}

.colorStyle-white-50 {
  --pill-bg-color: #{$color-white-50};
  --pill-color: #{$color-white};
}

.colorStyle-white {
  --pill-bg-color: #{$color-white};
  --pill-color: #{$color-text-secondary};
}

.colorStyle-tint {
  --pill-bg-color: #{$color-tint};
  --pill-color: #{$color-white};
}

.colorStyle-blue {
  --pill-bg-color: #{$color-support-1};
  --pill-color: #{$color-white};
}

.colorStyle-yellow {
  --pill-bg-color: #{$color-support-3};
  --pill-color: #{$color-white};
}

.colorStyle-green {
  --pill-bg-color: #{$color-support-2};
  --pill-color: #{$color-white};
}

.colorStyle-orange {
  --pill-bg-color: #{$color-support-6};
  --pill-color: #{$color-white};
}

.colorStyle-red {
  --pill-bg-color: #{$color-support-4};
  --pill-color: #{$color-white};
}

.colorStyle-grey {
  --pill-bg-color: #{$color-support-5};
  --pill-color: #{$color-text-secondary};
}

.colorStyle-darkGrey {
  --pill-bg-color: #{$color-text-secondary};
  --pill-color: #{$color-white};
}
