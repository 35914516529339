@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  z-index: 2;
  background-color: $nav-bg-color;
  position: fixed;
  left: 0;
  width: $nav-width;
  height: 100vh;
  transform: translateX(-100%);
  transition: transform 0.2s ease-out;
}

.styleNameIsVisible {
  transform: translateX(0);
}

.styleNameIsToggleable {
  box-shadow: rgba($color-text-primary, 0.4) 0 4px 4px 4px;
}
