@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  border-radius: 8px;
  border: solid 1px var(--input-divider-color, $color-support-5);
  width: 40px;
}

.styleNameAdd {
  border-style: dashed;
}

.styleNameImage {
  width: 120px;
}

.div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
  padding: 12px;
  color: $color-text-secondary;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
