@import '../../../styles/no-output';

.#{$MODULE_ROOT} {
  width: auto;

  :global(.react-datetime-picker__wrapper) {
    border: none;
  }
}

.picker {
  color: $input-text-color;
  font-weight: 600;

  &[class*='disabled'] {
    background-color: transparent;
    color: $input-text-color-disabled;
  }
}
