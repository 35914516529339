@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  @include padding(y, $page-padding-vertical);
  padding-right: $page-padding-horizontal;
  width: 100%;
  position: relative;
  padding-left: $nav-width + 49px;

  @media (min-width: $max-site-width + ($nav-width*2)) {
    max-width: $max-site-width;
    margin: 0 auto;
    padding-left: $page-padding-horizontal;
  }
}
