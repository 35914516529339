@import '../../styles/no-output';

.#{$MODULE_ROOT} {
  max-width: 256px;
  border-radius: 40px;
  background-color: $color-support-5;

  &:hover {
    background-color: $color-white;
  }
}
