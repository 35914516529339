@import '../../../styles/no-output';

.#{$MODULE_ROOT} {
  display: block;
  background-color: var(--input-bg-color, $color-white);
}

.hasBorder {
  border: solid 1px $color-support-5;
  border-radius: $inputgroup-border-radius;
  margin-bottom: 16px;
}

.isDisabled {
  --input-bg-color: #{$color-support-5};
  --input-divider-color: #{$color-white};
}
