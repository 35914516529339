@import '../../../styles/no-output';

.#{$MODULE_ROOT} {
  &:focus-within {
    z-index: 10;
  }

  > * {
    border-radius: 0;
  }
}
