@import '../../../styles/no-output';

.#{$MODULE_ROOT} {
  flex: 1 1 20%;
  padding: 8px;
  display: flex;
}

.cellTypeNoResults {
  justify-content: space-around;
  margin: 120px 0;
  color: $color-text-secondary;
}

.cellTypeXLarge {
  flex: 4 0 0;
}

.cellTypeLarge {
  flex: 3 0 0;
}

.cellTypeMedium {
  flex: 2 0 0;
}

.cellTypeSmall {
  flex: 1 0 0;
}

.cellTypeFixed {
  flex: 0 0 auto;
}

.cellTypeClick {
  flex: 1 1 100%;
  padding: 0;
}

.cellSizeSmall {
  flex-basis: 68px;
}

.cellSizeMedium {
  flex-basis: 140px;
}

.cellTypeRegion {
  flex: 0 0 90px;
  justify-content: center;
}

.cellTypePill {
  flex: 0 0 112px;
  justify-content: center;
}

.cellTypeProfileType {
  flex: 0 0 100px;
  // justify-content: flex-end;
}
.cellTypeActions {
  flex: 0 0 48px;
  justify-content: flex-end;
}

.cellAlignContentRight {
  justify-content: flex-end;
}
.cellAlignContentCenter {
  justify-content: center;
}
