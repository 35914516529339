@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  display: flex;
  flex-flow: column nowrap;
  cursor: default;
  z-index: 5;
  position: relative;
  max-height: 80vh;

  > * {
    //outline: dotted 1px black
  }
}
