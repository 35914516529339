@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  overflow: auto;
}

.styleNameOverflowVisible {
  // for small modals that contain dropdowns
  overflow: visible;
}
