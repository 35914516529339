@import '../../../styles/no-output';

.#{$MODULE_ROOT} {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  flex: 0 0 auto;
}

.styleNameCurrent {
  > .itemContainer {
    color: $color-tint;
    border-bottom: solid 2px;
    margin-bottom: -1px;
  }
}

.itemContainer {
  padding: 14px 0 6px;
  margin: 0 16px;
  text-decoration: none;
  color: $nav-font-color;
  white-space: nowrap;
  display: flex;
  flex-flow: column nowrap;

  > * {
    padding: 1px 0;
    flex: 1 1 50%;
    min-height: 10px;
  }
}
