@use 'sass:math';
@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  background-color: $nav-bg-color;
  display: grid;
  grid-template-columns: minmax(5%, 1fr) auto minmax(5%, 1fr);
  align-items: center;
  min-height: 64px;
  color: #fff;
  padding: math.div($page-padding-vertical, 2) $page-padding-horizontal;

  > div {
    grid-column: span 1;
  }
}
