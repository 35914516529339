@import '../../styles/no-output';

.#{$MODULE_ROOT} {
  border: none;
  background-color: var(--input-divider-color, currentColor);
  height: 0.9em;
  width: 1px;
  display: inline;
  @include margin(y, 0);
}
