@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  width: auto;
  background-color: transparent;
  box-shadow: rgba($color-text-primary, 0) 0 0 3px;
}

.isDragging {
  background-color: $color-white;
  box-shadow: rgba($color-text-primary, 0.3) 0 0 10px;
  border-radius: 8px;
}
