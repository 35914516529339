@import '../../../styles/no-output';

.#{$MODULE_ROOT} {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid transparent;
  min-height: 40px;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  background-color: var(--input-bg-color, #{$color-input-bg});
  color: $input-text-color;

  &:hover {
    background-color: var(--input-bg-color, $color-input-bg-hover);
  }

  &:focus-within {
    background-color: var(--input-bg-color, #{$color-input-bg});
    border: solid 1px $color-input-border-active;
    box-shadow: 0 1px 2px rgba(32, 84, 245, 0.3) inset;
  }

  &:focus {
    outline: none;
  }
}

.#{$MODULE_ROOT}[disabled],
.isReadonly {
  &,
  &:focus-within,
  &:hover {
    --input-bg-color: #{$color-support-5};
    box-shadow: none;
    background-color: #{$color-support-5};
    color: $input-text-color-disabled;
    -webkit-text-fill-color: $input-text-color-disabled;
    opacity: 1; /* required on iOS */
  }
  &,
  & * {
    cursor: not-allowed;
  }
}

.isReadonly {
  &,
  &:focus-within,
  &:hover {
    --input-bg-color: transparent;
    -webkit-text-fill-color: $color-text-secondary;
  }
}

.hasError {
  &,
  &:focus-within,
  &:hover {
    border: solid 1px rgba($color-support-4, 0.5);
    box-shadow: 0 0 4px 4px rgba($color-support-4, 0.08) inset;
  }
  &:hover {
    --input-bg-color: #{rgba($color-support-4, 0.03)};
  }
}

.small {
  width: 96px;
  height: 40px;
  border-radius: 8px;
  padding: 0 8px;
}
