@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  flex: 0 0 56px;
  border-top: solid 1px $color-support-5;
  box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.04);
  padding: 0 12px;
  border-radius: 0 0 16px 16px;
  overflow: hidden;
}
