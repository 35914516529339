@use 'sass:math';
@import '../../../styles/no-output';

$checkbox-size: 24px;
$spaceBetweenBoxAndLabel: 12px;
$markSize: 12px;

.typeRadio {
  --CheckRadioBoxRadius: 100px;
}

._CheckRadio {
  &:before {
    background-color: $color-support-5;
    width: $checkbox-size;
    height: $checkbox-size;
    border: none;
    border-radius: var(--CheckRadioBoxRadius, 4px);
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &.inverted:before {
    background-color: $color-white;
  }

  &:after {
    content: '';
    border-radius: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: $checkbox-size - $markSize;
    height: $checkbox-size - $markSize;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: math.div($markSize, 2);
    transition: all 0.2s;
  }
}

._showMark {
  &:after {
    opacity: 1;
    transform: scale(1) translateY(-50%);
  }
}

._hideMark {
  &:after {
    opacity: 0;
    transform: scale(0);
  }
}

._CheckRadioChecked {
  color: $input-text-color;
  &:before {
    background-color: $color-tint;
  }

  &:after {
    opacity: 1;
    transform: scale(1) translateY(-50%);
  }
}

._CheckRadioCheckedDisabled {
  color: $color-text-tertiary;
  &:before {
    background-color: $color-disabled;
  }

  &:after {
    color: $color-text-secondary;
  }
}

._CheckRadioDisabled {
  cursor: not-allowed;
  color: $color-text-secondary;
}

._CheckRadioHalfChecked {
  &:before {
    background-color: $color-tint;
  }

  &:after {
    $dashThickness: 2px;
    content: '';
    background-color: white;
    border-radius: $dashThickness;
    width: $markSize;
    height: $dashThickness;
    top: 50%;
    left: math.div($markSize, 2);
    transform: translateY(-50%);
    background-image: none;
  }
}

.label {
  font-weight: 600;
  color: $color-text-secondary;

  .typeRadio &:after {
    background-color: white;
  }
}

.#{$MODULE_ROOT} {
  display: flex;

  > .input {
    opacity: 0;
    position: absolute;
  }

  > .input + .label {
    position: relative;
    padding-left: $checkbox-size + $spaceBetweenBoxAndLabel;
    cursor: pointer;

    @extend ._CheckRadio;
  }

  &.noLabel {
    flex: 0 0 auto;
    > .input + .label {
      padding-left: $checkbox-size;
    }
  }

  > .input:not(:checked) + .label {
    @extend ._hideMark;
  }

  > .input:disabled:not(:checked) + .label {
    &:before {
      background-color: $color-disabled;
    }
  }

  > .input:checked + .label {
    @extend ._CheckRadioChecked;
  }

  > .input:disabled:checked + .label {
    @extend ._CheckRadioCheckedDisabled;
  }

  > .input:disabled + .label {
    @extend ._CheckRadioDisabled;
  }

  > .input:checked:focus + .label,
  > .input:not(:checked):focus + .label {
    &:before {
      box-shadow: map-get($shadow, a);
      outline: none;
    }
  }
}

.#{$MODULE_ROOT} {
  > .input.status-full + .label {
    @extend ._CheckRadioChecked;
    @extend ._showMark;
  }

  > .input.status-half.status-half + .label {
    // strongify
    @extend ._CheckRadioHalfChecked;
    @extend ._showMark;
  }
}
