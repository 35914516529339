@import '../../../styles/no-output';

.#{$MODULE_ROOT} {
  position: absolute;
  @include pin();
  box-shadow: 0 0 6px 4px rgba($color-support-5, 0.5);
  background-color: rgba($color-support-5, 0.5);
  border-radius: 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.body {
  font-size: 14px;
  line-height: 14px;
  padding: 3px 4px;
  border-radius: 20px;
  background-color: rgba($color-support-5, 0.8);
  box-shadow: 0 0 6px 4px rgba($color-support-5, 0.8);
}
