@mixin multiline-ellipsis($max-height, $lines-to-show) {
  display: block;
  max-width: 100%;
  max-height: $max-height; // Fallback for non-webkit
  overflow: hidden;
  text-overflow: ellipsis;

  @if $lines-to-show == 1 {
    // we don't want multiline ellipsis, it will ellipsify on words instead of letters
    height: $max-height;
    white-space: nowrap;
  } @else {
    display: -webkit-box;
    -webkit-line-clamp: $lines-to-show;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }
}

@mixin maxLines($lines-to-show, $fontsize: 16px, $lineheight: 1.5) {
  $lh: $lineheight;
  @if unitless($lineheight) {
    $lh: $lineheight * $fontsize;
  }
  $maxheight: $lh * $lines-to-show;
  @include multiline-ellipsis($maxheight, $lines-to-show);
}
