@import '../../styles/no-output.scss';

$size-of-circle: 5.5em; // body font-size: 16px, designed circle-size: 88px 88/16 = 5.5
$size-of-logo: 3.5em; // body font-size: 16px, designed logo-size: 56px   56/16 = 3.5
$size-of-label: 0.75em; // body font-size: 16px, designed label-size: 24px  24/16 = 1.5

:root {
  --visual-border-radius: 8px;
}

.#{$MODULE_ROOT} {
  position: relative;
  border-radius: var(--visual-border-radius);

  img {
    border-radius: var(--visual-border-radius);
  }
}

.fallback {
  font-weight: 700;
  color: inherit;
  font-size: 2em;
}

.label {
  position: absolute;
  right: 0;
  top: 0;
}

.assetLogo {
  border: solid $color-support-5 1px;
}

.figure {
  margin: 0;
  box-shadow: map-get($shadow, b);
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: var(--visual-border-radius);

  > :first-child {
    border-radius: var(--visual-border-radius);
  }
}

.styleNameRound {
  --visual-border-radius: 999px;
  width: $size-of-circle;
  height: $size-of-circle;

  .figure {
    margin: 0;
    box-shadow: map-get($shadow, b);
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .img,
  svg {
    flex: 0 0 auto;
    width: $size-of-logo;
  }
  &.styleNameRound {
    flex-shrink: 0; // make sure round things don't shrink, even when they're in a flex-context
  }
}

.styleNameNoShade {
  .circle {
    box-shadow: none;
  }
}

.styleNameRounded {
  width: $size-of-circle;
  height: $size-of-circle;

  .img {
    max-width: 999px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
