@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  border: none;
  background-color: var(--input-divider-color, $color-support-5);
  height: 1px;
  margin: var(--divider-x-margin, 0) var(--divider-y-margin, 0);
}

.margin {
  margin-top: 24px;
}
