@import '../../styles/no-output.scss';
// overwrites stuff from default css found at node_modules/react-toastify/dist/ReactToastify.css



.styleToastCreate {
  &:global {
    &.Toastify__toast--default {
      color: $color-text-primary;
    }

    &.Toastify__toast {
      overflow: visible;
      padding: 0;
      width: 504px;
      box-shadow: rgba(0, 0, 0, 0.6) 0 0 17px 0, rgba(0, 0, 0, 0.6) 0 0 4000px 4000px;

      margin-left: -94px;

      .Toastify__toast-body {
        max-width: 100%;
      }
    }
  }
}

.styleToastError {
  &:global {
    &.Toastify__toast--error {
      .Toastify__toast-body {
        width: 95%;
      }
    }
  }
}

.styleToastInfo {
  &:global {
    &.Toastify__toast--info {
      max-height: 75vh;
      background: $color-white;
      border: 1px solid $color-white;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
      color: $color-text-secondary;

      .Toastify__close-button--info {
        color: $color-text-secondary !important;
      }
    }
  }
}

:global .Toastify__toast {
  border-radius: 16px !important;
  font-family: $gain-primary-font-stack !important;
}
:global {
  .Toastify__toast-container--top-center,
  .Toastify__toast-container--top-left,
  .Toastify__toast-container--top-right {
    top: 72px !important;
  }
}

:global .Toastify__toast-body {
  img {
    margin-top: 8px;
    border: solid 1px $color-text-primary;
    width: 100% !important;
  }
}
