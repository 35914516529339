@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  @include padding(y, $page-padding-vertical);
  padding-right: $page-padding-horizontal;
  width: 100%;
  max-width: $max-site-width;
  margin: 0 auto;
  padding-left: $page-padding-horizontal;
}

.styleNameWithOpenedNav {
  padding-right: $page-padding-horizontal;
  padding-left: $nav-width + 49px;
  width: 100%;
  max-width: 100%;
  margin: 0;

  @media (min-width: $max-site-width + ($nav-width*2)) {
    max-width: $max-site-width;
    margin: 0 auto;
    padding-left: $page-padding-horizontal;
  }
}
