@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  width: auto;
  border-radius: $inputgroup-border-radius;
  background-color: var(--input-bg-color, $color-white);
}

.isDragging {
  background-color: $color-input-bg-hover;
  box-shadow: map-get($shadow, i);

  [draggable] {
    background-color: $color-white;
    border-radius: $inputgroup-border-radius;
  }
}
