@import '../../../styles/no-output';

.#{$MODULE_ROOT} {
  color: $color-text-primary;
  margin-bottom: 11px;
}

.styleNamePrimary {
  margin-top: 6px;
  margin-bottom: 8px;
  color: $color-text-primary;
  @include body_1();
  font-weight: bold;
  padding-left: 8px;
}

.styleNameSecondary {
  margin-top: 6px;
  margin-bottom: 8px;
  color: $color-text-tertiary;
  @include header_6();
  font-weight: bold;
  padding-left: 8px;
}
