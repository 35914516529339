@import '../../styles/no-output.scss';

$earsize: 60;
$beamsize: 4px;

.#{$MODULE_ROOT} {
  position: fixed;
  left: 0;
  top: 0;
  width: $earsize * 1px;
  height: $earsize * 1px;
  z-index: 1000;
  pointer-events: none;
}

.environment-LOCAL {
  $colorAsRgb: hexToRgbArray($color-tint);
  --environment-color-rgb: #{$colorAsRgb};
  --environment-color: #{'rgb(var(--environment-color-rgb))'};
}

.environment-ACC {
  $colorAsRgb: hexToRgbArray($color-support-2);
  --environment-color-rgb: #{$colorAsRgb};
  --environment-color: #{'rgb(var(--environment-color-rgb))'};
}

.environment-TEST {
  $colorAsRgb: hexToRgbArray($color-support-3);
  --environment-color-rgb: #{$colorAsRgb};
  --environment-color: #{'rgb(var(--environment-color-rgb))'};
}

.environment-EXP {
  $colorAsRgb: hexToRgbArray($color-support-3);
  --environment-color-rgb: #{$colorAsRgb};
  --environment-color: #{'rgb(var(--environment-color-rgb))'};
}

.beam {
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--environment-color);
}
.beamLeft {
  @extend .beam;
  bottom: 0;
  width: $beamsize;
}

.beamTop {
  @extend .beam;
  right: 0;
  height: $beamsize;
}

.ear {
  transform: translate(-50%, -50%) rotate(-45deg);
  transform-origin: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: sqrt(($earsize * $earsize) + ($earsize * $earsize)) * 1px;
  box-shadow: #{'rgba(var(--environment-color-rgb),.3)'} 0 0 12px 12px;
}

.txtWrap {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  height: $earsize * 1px;
  background-color: var(--environment-color);
}

.txt {
  color: $color-white;
  @include caption_3();
  padding: 4px;
}
