$gridspace: 26px;

$inputgroup-border-radius: 16px;
$nav-width: 248px;
$max-site-width: 1200px;

@import 'names.scss';
@import 'breakpoints.scss';
@import 'colors.scss';
@import 'spaces.scss';
@import 'shadows.scss';
@import 'fonts.scss';
@import 'fontSizes.scss';
@import 'zIndex.scss';
@import 'components';
