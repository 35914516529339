@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  // height: 1.25em; // why?
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  max-width: 100%;
  position: relative;

  .icon {
    flex: 0 0 auto;
    margin-right: 0.333em;

    svg {
      position: relative;
      fill: currentColor;
    }
  }
  .iconText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1 auto;
  }
}

.iconAfter {
  .icon {
    order: 2;
    margin-left: 0.333em;
    margin-right: 0;
  }

  .iconText {
    order: 1;
  }
}
