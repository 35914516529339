@import '../../styles/no-output';

.#{$MODULE_ROOT} {
  padding: 0 12px;
  max-height: 1px;
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
}

.isOpen {
  padding: 12px 12px 24px 12px;
  max-height: fit-content;
  overflow: visible;
  box-shadow: map-get($shadow, i);
}
