@use 'sass:math';
@import '../../styles/no-output';

.#{$MODULE_ROOT} {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% + #{var(--item-space, 8px)});

  > *:last-child {
    margin-right: 0;
  }

  > * {
    padding-right: var(--item-space, 8px);
    padding-bottom: var(--item-space, 8px);
  }
}

@for $columnAmount from 1 through 7 {
  .columns-#{$columnAmount} {
    > * {
      flex-basis: math.div(100, $columnAmount) * 1%;
    }
  }
}
