@import '../../../styles/no-output';

.#{$MODULE_ROOT} {
  padding: 4px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border-bottom: solid 1px $color-support-5;
  border-left: solid 2px transparent;
  border-right: solid 2px transparent;
  transition: all 0.1s linear;

  &[data-haslink] {
    border: none;
    padding: 0;
  }

  > * {
    min-width: 0;
  }
}

.rowHead {
  box-shadow: map-get($shadow, c);
  align-items: flex-end;
  overflow: hidden;
  border: none;
  &:hover {
    box-shadow: map-get($shadow, c);
  }
}

.rowHover:hover {
  border-left-color: $color-tint;
  border-right-color: $color-tint;
  box-shadow: map-get($shadow, i);
}
