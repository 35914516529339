@import '../../../styles/no-output';

.#{$MODULE_ROOT} {
  margin: 20px auto;
  padding: 2px;
}

.logo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-content: center;
  padding-top: 5px;

  > * {
    width: 60%;
    height: auto;
  }
}

.gainText {
  font-weight: 900;
  color: $color-white;
  font-size: 18px;
  line-height: 18px;
}
