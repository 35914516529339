$spaces: (
  XXL: 36px,
  XL: 30px,
  L: 24px,
  M: 18px,
  S: 12px,
  XS: 6px,
  XXS: 4px,
);

$page-padding-horizontal: 36px;
$page-padding-vertical: 18px;
$modal-padding-horizontal: 16px;
