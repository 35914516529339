@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  width: 0;
  flex: 0 0 0;
}

.closeIcon {
  position: absolute;
  right: 28px;
  top: 28px;
  transform: translate(0%, -50%);
  cursor: pointer;
  z-index: 8;
  width: 14px;
  height: 14px;
}

.closeArea {
  // dirrrty hack: fixed won't work within a stacking context that contains transforms.
  position: absolute;
  left: -100vw;
  right: -100vw;
  top: -100vw;
  bottom: -100vw;
  z-index: 1;
  cursor: pointer;
}
