@import '../../../styles/no-output';

.inputAndTextarea {
  display: block;
  border: none;
  font-size: 16px;
  line-height: 24px;
  height: 24px;
  width: 100%;
  color: inherit;
  @include caption_1;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    //-webkit-appearance: none;
  }

  &::placeholder {
    color: $color-text-secondary;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: inherit;
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    background-color: #fff;
    background-clip: content-box;
  }
}

.alignInputCenter {
  text-align: center;
}

.textInput {
  @extend .inputAndTextarea;

  &.noUpDownArrows {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.textarea {
  @extend .inputAndTextarea;
  resize: vertical;
  min-height: 24px;
  height: auto;
}

.before,
.after {
  color: inherit;
}

.before {
  margin-right: 6px;
  font-size: 14px;
}

.after {
  margin-left: 6px;
  font-size: 14px;
}
