@import '../../styles/no-output.scss';

:root {
  --aspect-ratio: 4/3;
}
.#{$MODULE_ROOT} {
  width: 100%;

  &:before {
    content: '';
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  .content {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.styleNameCover {
  img {
    object-fit: cover;
  }
}
