@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  width: 0;
  flex: 0 0 0;
  height: 30px;
}

.closeIcon {
  position: absolute;
  right: 8px;
  top: 16px;
  transform: translate(0%, -50%);
  cursor: pointer;
  z-index: 8;
  width: 14px;
  height: 14px;
}
