@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  &::selection {
    background-color: $color-tint;
    color: $color-white;
  }
}

.center {
  text-align: center;
}

@each $colorname, $color in $colors {
  .#{'' + $colorname} {
    color: $color;
  }
}

@for $lines from 0 through 4 {
  @each $font, $map in $fontSizes {
    .#{$font}-#{$lines} {
      @include fontSizeFromMap($map, $lines);
    }
  }
}
