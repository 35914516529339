@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  position: fixed;
  z-index: 999;
}

.showMenuButton {
  background-color: $nav-bg-color;
  position: fixed;
  left: 30px;
  top: 15px;
  width: 40px;
  height: 40px;
  padding: 10px;
  border: none;
  color: #fff;
  border-radius: 4px;

  &:focus {
    outline: none;
  }
}

.hideMenuButton {
  z-index: 3;
  position: fixed;
  left: $nav-width;
  top: 8px;
  width: 30px;
  height: 30px;
  padding: 10px;
  background-color: transparent;
  border: none;
  color: #fff;
  transform: translateX(-100%);

  &:focus {
    outline: none;
  }
}

.overlay {
  position: fixed;
  @include pin();
  transform: translateX(-100%);
  background-color: rgba($color-text-primary, 0.3);
  opacity: 0;
  transition: opacity 0.2s linear;
}

.overlayVisible {
  transform: translateX(0);
  opacity: 1;
}
