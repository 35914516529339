@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  flex: 0 0 56px;
  border-bottom: solid 1px $color-support-5;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
}
