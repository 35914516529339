@import '../../styles/no-output.scss';

$button-space: 12px;

.#{$MODULE_ROOT} {
  position: relative;
  height: 56px;
  width: 100%;
  display: block;
  padding: $button-space;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  color: var(--button-color, $color-white);
  background-color: var(--button-bg-color, $color-tint);

  &:focus {
    outline: none;
  }

  &[disabled],
  &:disabled,
  &.disabled {
    --button-bg-color: #{$color-text-secondary};
  }
}

.elements {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  > * {
    margin-right: 8px;
  }

  > *:last-child {
    margin-right: 0;
  }
}

.before {
  flex: 0 1 1em;
  margin-right: 0.5em;
}

.after {
  flex: 0 1 1em;
  margin-left: 0.5em;
}

.spinner {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  color: inherit;
  box-shadow: var(--button-bg-color, $color-tint) 0 0 10px 10px;
  background-color: var(--button-bg-color, $color-tint);
  z-index: 1;
}

.spinner-before {
  @extend .spinner;
  left: $button-space;
}

.spinner-after {
  @extend .spinner;
  right: $button-space;
}

.spinner-center {
  @extend .spinner;
  left: 50%;
  transform: translate(-50%, -50%);
}

.body {
  margin: 4px 0;
  flex: 1 1 auto;
  text-align: center;
  @include button(1);
}

.styleNameButtonPill {
  border-radius: 100px;
  height: 32px;
  width: auto;
  display: flex;
  padding: 0 $button-space;

  .body {
    @include button_pill(1);
    text-decoration: none;
  }
}

.styleNameSecondary {
  --button-bg-color: #{rgba($color-tint, 0.08)};
  --button-color: #{$color-tint};

  .spinner {
    box-shadow: #{rgb(237, 241, 254)} 0 0 10px 10px;
    background-color: #{rgb(237, 241, 254)};
  }
}

.styleNameAlert {
  --button-bg-color: #{$color-support-4};
}

.styleNameAlertSoft {
  --button-bg-color: #{rgba($color-support-4, 0.08)};
  --button-color: #{$color-support-4};
}

.styleNameTint {
  --button-bg-color: #{$color-tint};
  --button-color: #{$color-white};
}

.styleNameWhite {
  --button-bg-color: #{$color-white};
  --button-color: #{$color-tint};

  &[disabled],
  &:disabled,
  &.disabled {
    --button-bg-color: #{$color-support-5};
    --button-color: #{$color-text-secondary};
  }
}

.styleNameGrey {
  --button-bg-color: #{$color-text-secondary};
  --button-color: #{$color-white};

  &[disabled],
  &:disabled,
  &.disabled {
    --button-bg-color: #{$color-support-5};
    --button-color: #{$color-text-secondary};
  }
}
