@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  @include padding(y, $page-padding-vertical);
  padding-left: $page-padding-horizontal;
  padding-right: $page-padding-horizontal;
  width: 100%;
  max-width: $max-site-width;
  position: relative;
  margin: 0 auto 300px;
  z-index: map-get($zIndex, pageContent);
}

.styleNameWithTabbar {
  --header-height: 122px;
}

.styleNoBottomMargin {
  margin-bottom: 0;
}
