@import '../../styles/no-output';

.#{$MODULE_ROOT} {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: stretch;
  border: solid 1px $color-support-5;
  border-radius: 0 0 12px 12px;
  margin-top: -1px;
  background-color: $color-white;
}

.styleNameHeader {
  border-radius: 12px 12px 0 0;
}
