@import '../../styles/no-output';

.#{$MODULE_ROOT} {
  position: relative;
  outline: none;
}

.box {
  z-index: 5; // under stickytableheader
  position: absolute;
  min-width: 176px;
  border-radius: 16px;
  border: solid 1px $color-support-5;
  background-color: $color-white;
  right: 0;
  top: 50%;
  transform-origin: right top;

  box-shadow: map-get($shadow, b);
  color: $color-text-primary;
  transition: all ease-in 0.13s;
}

.boxOpen {
  @extend .box;
  opacity: 1;
  transform: scale(1) translateY(20px);
}
.boxClosed {
  @extend .box;
  opacity: 0;
  transform: scale(0.01) translateY(0);
}
