@import '../../styles/no-output';

.#{$MODULE_ROOT} {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;

  > *:last-child {
    margin-right: 0;
  }

  > * {
    min-width: 0;
    flex: 0 1 auto;
    margin-right: var(--item-space, 8px);
  }
}

.styleNameMultiLine {
  margin-top: calc(-1 * var(--item-space, 8px));

  > * {
    margin-top: var(--item-space, 8px);
  }
}

.styleNameBorderedItems {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  grid-auto-rows: 51px;
  grid-gap: 0;

  > * {
    padding: 4px;
    border: solid 1px var(--input-divider-color, $color-support-5);
    border-right: none;
    margin: -1px;
  }
}

.styleNameCenteredItems {
  justify-content: center;
}

.styleNameNowrap {
  flex-flow: row nowrap;
}

.styleNameSpaceBetween {
  justify-content: space-between;
}

.styleNameGrow {
  justify-content: stretch;
  > * {
    flex: 1 1 auto;
  }
}
