$layout-sides: (
  t,
  // top
  r,
  // right
  b,
  // bottom
  l,
  // left
  x,
  // horizontal, so left && right
  y,
  // vertical, so top && bottom
  all,
  // top && right && bottom && left
  none
);

$layout-sizes: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 0.75rem,
  4: 1rem,
  5: 1.25rem,
  6: 1.5rem,
  8: 2rem,
  10: 2.5rem,
  12: 3rem,
  16: 4rem,
  20: 5rem,
  24: 6rem,
  32: 8rem,
  px: 1px,
  auto: auto,
);

@mixin sides($property: undefined, $side: all, $value: 0) {
  $infix: if($property, '-', '');

  $value-top: null;
  $value-right: null;
  $value-bottom: null;
  $value-left: null;

  @if $side == t or $side == top {
    $value-top: $value;
  } @else if $side == r or $side == right {
    $value-right: $value;
  } @else if $side == b or $side == bottom {
    $value-bottom: $value;
  } @else if $side == l or $side == left {
    $value-left: $value;
  } @else if $side == x or $side == horizontal {
    $value-left: $value;
    $value-right: $value;
  } @else if $side == y or $side == vertical {
    $value-top: $value;
    $value-bottom: $value;
  } @else {
    $value-top: $value;
    $value-right: $value;
    $value-bottom: $value;
    $value-left: $value;
  }

  #{$property}#{$infix}top: $value-top;
  #{$property}#{$infix}right: $value-right;
  #{$property}#{$infix}bottom: $value-bottom;
  #{$property}#{$infix}left: $value-left;
}

@mixin m($side, $value: 0) {
  @include sides('margin', $side, $value);
}

@mixin margin($side, $value: 0) {
  @include m($side, $value);
}

@mixin p($side, $value: 0) {
  @include sides('padding', $side, $value);
}

@mixin padding($side, $value: 0) {
  @include p($side, $value);
}

@mixin pin($side: all, $value: 0) {
  @include sides(null, $side, $value);
}
