@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  svg {
    max-height: 1em;
    max-width: 1em;
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    fill: currentColor;
  }
}

.pointUp {
  transform: rotate(0deg);
}

.pointRight {
  transform: rotate(90deg);
}

.pointDown {
  transform: rotate(180deg);
}

.pointLeft {
  transform: rotate(-90deg);
}
