@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.listItem {
  border-bottom: solid 1px $color-support-5;
  margin: 0;
  &:last-of-type {
    border-bottom: none;
  }
}
