$nav-bg-color: #1b2733;
$nav-font-color: #8898a9;
$page-bg-color: rgb(250, 250, 252);

$colors: (
  text-primary: #343c47,
  text-secondary: #71829c,
  text-tertiary: #a7b5cc,
  white: #fff,
  white50: rgba(#fff, 0.5),
  white70: rgba(#fff, 0.7),
  asset-card: #d8dfe7,
  owner-card: #cedeef,
  bg-1: #fff,
  bg-2: #f7f9fa,
  support-1: #3b85d8, // In MUI: pallette.primary.main
  support-2: #45bfa2,
  support-3: #edbd2e,
  support-4: #ed5565,
  support-5: #eaecee,
  support-6: #ed7e2e,
  tint: #2054f5,
  shadow: rgba(0, 0, 0, 0.06),
);

$color-input-bg: #ffffff;
$color-input-bg-hover: map-get($colors, bg-2);

$color-input-border: transparent;
$color-input-border-active: map-get($colors, tint);

$color-text-primary: map-get($colors, text-primary);
$color-text-secondary: map-get($colors, text-secondary);
$color-text-tertiary: map-get($colors, text-tertiary);

$color-white: map-get($colors, white);
$color-white-50: map-get($colors, white50);
$color-white-70: map-get($colors, white70);

$color-bg-1: map-get($colors, bg-1);
$color-bg-2: map-get($colors, bg-2);

$color-asset-card: map-get($colors, asset-card);
$color-owner-card: map-get($colors, owner-card);

$color-support-1: map-get($colors, support-1);
$color-support-2: map-get($colors, support-2);
$color-support-3: map-get($colors, support-3);
$color-support-4: map-get($colors, support-4);
$color-support-5: map-get($colors, support-5);
$color-support-6: map-get($colors, support-6);

$color-tint: map-get($colors, tint);

$color-shadow: map-get($colors, shadow);

$color-disabled: #ddd;

$input-text-color: $color-text-primary;
$input-text-color-disabled: $color-text-primary;
$read-only-table-bg-color: $color-support-5;
