@use 'sass:math';

@mixin fontSize($fontSize: 16, $lineHeight: 20, $maxLines: 0) {
  $fontSizePx: $fontSize * 1px;
  $lineHeighUnitless: math.div($lineHeight, $fontSize);

  font-size: $fontSizePx;
  line-height: $lineHeight * 1px;

  @if ($maxLines > 0) {
    @include maxLines($maxLines, $fontSizePx, $lineHeighUnitless);
  }
}

@mixin fontSizeFromMap($map, $lines: 0) {
  $_fontSize: map_get($map, size);
  $_lineHeight: map_get($map, lineHeight);

  @include fontSize($_fontSize, $_lineHeight, $lines);
  font-weight: map_get($map, weight);
}

@mixin header_1($lines: 0) {
  $_font: map_get($fontSizes, header-1);
  @include fontSizeFromMap($_font, $lines);
}

@mixin header_2($lines: 0) {
  $_font: map_get($fontSizes, header-2);
  @include fontSizeFromMap($_font, $lines);
}

@mixin header_3($lines: 0) {
  $_font: map_get($fontSizes, header-3);
  @include fontSizeFromMap($_font, $lines);
}

@mixin header_4($lines: 0) {
  $_font: map_get($fontSizes, header-4);
  @include fontSizeFromMap($_font, $lines);
}

@mixin header_5($lines: 0) {
  $_font: map_get($fontSizes, header-5);
  @include fontSizeFromMap($_font, $lines);
}

@mixin header_6($lines: 0) {
  $_font: map_get($fontSizes, header-6);
  @include fontSizeFromMap($_font, $lines);
}

@mixin body_1($lines: 0) {
  $_font: map_get($fontSizes, body-1);
  @include fontSizeFromMap($_font, $lines);
}

@mixin body_2($lines: 0) {
  $_font: map_get($fontSizes, body-2);
  @include fontSizeFromMap($_font, $lines);
}

@mixin body_3($lines: 0) {
  $_font: map_get($fontSizes, body-3);
  @include fontSizeFromMap($_font, $lines);
}

@mixin caption_1($lines: 0) {
  $_font: map_get($fontSizes, caption-1);
  @include fontSizeFromMap($_font, $lines);
}

@mixin caption_2($lines: 0) {
  $_font: map_get($fontSizes, caption-2);
  @include fontSizeFromMap($_font, $lines);
}

@mixin caption_3($lines: 0) {
  $_font: map_get($fontSizes, caption-3);
  @include fontSizeFromMap($_font, $lines);
}

@mixin button($lines: 0) {
  $_font: map_get($fontSizes, button);
  @include fontSizeFromMap($_font, $lines);
}

@mixin button_pill($lines: 0) {
  $_font: map_get($fontSizes, button-pill);
  @include fontSizeFromMap($_font, $lines);
}
