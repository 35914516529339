@import '../../../styles/no-output';

.#{$MODULE_ROOT} {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  margin-right: var(--item-space);
  min-width: 0;

  &:last-child {
    margin-right: 0;
  }
}

.flex-item-auto {
  flex: 0 0 auto;
}

.flex-25-percent {
  flex: 0 0 25%;
}

.flex-50-percent {
  flex: 0 0 50%;
}

.flex-50-px {
  flex: 0 0 50px;
}

.flexitem-roundbutton {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  align-self: center;
}
