@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  padding: 12px 16px;
  white-space: nowrap;
  cursor: pointer;
}

.inactive {
  cursor: default;
  opacity: 0.5;
}

.link {
  display: block;
}
