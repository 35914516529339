@import 'no-output.scss';
@import 'normalize/normalize.scss';
@import 'typography/fontface.scss';

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  display: flex;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: $gain-primary-font-stack;
  color: $color-text-primary;
  @include body_1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: var(--scrollbarCompensation, 0);
  background-color: $page-bg-color;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

a {
  -webkit-tap-highlight-color: transparent;
}

.tooltip {
  margin: 10px 0;
  z-index: 9999;
}

$checkbox-size: 20px;
$spaceBetweenBoxAndLabel: 12px;
$markSize: 12px;

// Apparently, it's not possible to add this `url(...)` in the
// `CheckRadio.module.scss` file, it is handled differently and
// the path to the SVG won't be correct.
// Furthermore, using this `url(...)` in conjunection with a
// CSS variable causes Safari to end up in an endless loop that
// keeps on loading the SVG file. This causes a permanent flicker
// in the checkmark and a huge performance hit.
.fix-checkbox-icon {
  &:after {
    background: url('../assets/icons/checkmark-css.svg');
  }
}
