@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  padding: 12px 16px;
  border-radius: 8px;
  background-color: var(--note-color-background, $color-support-1);
  color: var(--note-color, $color-white);
  margin: 12px 0;
}

.styleNameWarning {
  text-align: center;
  --note-color-background: #{$color-support-3};
}

.styleNameError {
  text-align: center;
  --note-color-background: #{$color-support-4};
}

.styleNameInfo {
  --note-color-background: #{$color-support-5};
  --note-color: #{$color-text-primary};
}
