@import '../../../styles/no-output';

.#{$MODULE_ROOT} {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  background-color: $color-white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-bottom: solid 1px $color-support-5;
}
