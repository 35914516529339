@import '../../styles/no-output.scss';

.#{$MODULE_ROOT} {
  max-height: 75vh;
  display: flex;
  flex-flow: column;
  align-items: stretch;
}

.modalTitle,
.modalFooter,
.modalBody {
  padding-left: 8px;
  padding-right: 8px;
}

.modalTitle {
  flex: 0 0 auto;
  padding-top: 2px;
}

.modalBody {
  flex: 0 1 auto;
  overflow: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.contentTitle {
  margin-bottom: 4px;
}

.modalFooter {
  flex: 0 0 auto;
  display: flex;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
